table.dataTable {
  margin: 0 !important;
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 10px !important;
  content: "\f0d7" !important;
  font-family: "Font Awesome 5 Free";
  bottom: 8px !important;
  color: $gray-200 !important;
  font-size: 10px !important;
  opacity: 1 !important;
  position: absolute;
  font-weight: $font-weight-semibold;
  line-height: 1px !important;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 10px !important;
  content: "\f0d8" !important;
  font-family: "Font Awesome 5 Free";
  top: 18px !important;
  color: $gray-200 !important;
  font-size: 10px !important;
  opacity: 1 !important;
  position: absolute;
  font-weight: $font-weight-semibold;
  line-height: 1px !important;
}
.dataTables_paginate .paging_numbers {
  float: right;
}
.dataTables_info {
  font-size: $font-size-15;
  color: $gray-700;
  font-weight: $font-weight-medium;
  @include respond-below(md) {
    font-size: $font-size-12;
  }
}
.custom-select {
  min-width: 80px;
  // background: url(../../../../public/assets/img/icons/dropdown.svg) no-repeat 95% 50%;
}
.dataTables_paginate .pagination {
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  li {
    margin: 0 2px;
    a {
      background: transparent;
      border-color: transparent;
      border-radius: 5px !important;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-700;
      font-size: $font-size-14;
      &:hover {
        background: $primary;
        color: $white;
      }
    }
    &.active a.page-link {
      background: $primary;
      border-color: $primary;
      @include rounded(5px);
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.previous.disabled,
    &.prev {
      width: auto;
      margin: 0 13px 0 0;
      a {
        background-color: transparent;
        border: 0;
        width: auto;
        padding: 0;
        i {
          margin-right: 8px;
        }
        &:hover {
          color: $green;
        }
      }
    }
    &.next.disabled,
    &.next {
      width: auto;
      margin: 0 0 0 13px;
      a {
        background-color: transparent;
        border: 0;
        width: auto;
        padding: 0;
        i {
          margin-left: 8px;
        }
        &:hover {
          color: $green;
        }
      }
    }
  }
  @include respond-below(md) {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}
.dataTables_length {
  margin-bottom: 15px;
}
.dataTables_paginate {
  margin-top: 15px !important;
}
.dataTables_length {
  label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: $gray-700;
    font-size: $font-size-14;
    .form-select {
      width: 61px;
      border: 1px solid $light-900;
      border-radius: 6px;
      color: $gray-700;
      margin: 0 8px;
      background-position: right 5px center;
      padding-right: 15px;
    }
  }
}
.datatable-length {
  @include respond-below(md) {
    text-align: center;
  }
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 15px;
}
table.dataTable thead > tr > th.no-sort.sorting_asc:before {
  display: none;
}
table.dataTable thead > tr > th.no-sort.sorting_asc::after {
  display: none;
}
table.dataTable thead > tr > th.no-sort.sorting:before {
  display: none;
}
table.dataTable thead > tr > th.no-sort.sorting::after {
  display: none;
}
.custom-table .table-responsive {
  border: 1px solid $light-900;
  border-radius: 6px;
}
table.table.dataTable {
  & > tbody {
    > tr {
      border-color: $light-900;
      td {
        color: $gray-700;
        border-bottom: 1px solid $light-900;
        padding: 12px 20px;
      }
    }
  }
  & > thead > tr {
    border-color: $light-900;
    th {
      color: $gray-900;
      font-size: $font-size-14;
      font-weight: $font-weight-medium;
      padding: 12px 20px;
    }
  }
}
.dataTables_filter {
  margin-bottom: 15px;
}
#select-all {
  ~ .checkmarks {
    top: -7px;
  }
}
#select-all2 {
  ~ .checkmarks {
    top: -7px;
  }
}
#select-all3 {
  ~ .checkmarks {
    top: -7px;
  }
}
.custom-datatable-filter [class*="col-md"] {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.no-datatable_length {
  .dataTables_length {
    display: none;
  }
  .dataTables_filter {
    display: none;
  }
  .dataTables_info {
    display: none;
  }
  .dataTables_paginate {
    display: none;
  }
}
.datatable-info {
  .dataTables_info {
    display: none !important;
    &:first-child {
      display: block !important;
    }
  }
}
.datatable-length {
  .dataTables_length {
    display: none !important;
    &:first-child {
      display: block !important;
    }
  }
}
.datatable-paginate {
  .dataTables_paginate {
    display: none !important;
    &:first-child {
      display: block !important;
    }
  }
}
