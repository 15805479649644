.layout-box-mode .main-wrapper {
  background: $light-200;
  max-width: 1350px;
  margin: auto;
  @include respond-below(xxl) {
    max-width: 1170px;
  }
  @include respond-below(xl) {
    max-width: 950px;
  }
  @include respond-below(lg) {
    max-width: 100%;
  }
}
.layout-box-mode .header {
  background: $light-200;
  max-width: 1350px;
  margin: auto;
  @include respond-below(xxl) {
    max-width: 1170px;
  }
  @include respond-below(xl) {
    max-width: 950px;
  }
  @include respond-below(lg) {
    max-width: 100%;
  }
}
.layout-box-mode .sidebar {
  background: $light-200;
  left: unset;
}
.layout-box-mode .page-wrapper {
  .content {
    @include respond-below(lg) {
      padding-right: 15px;
      background: $light-200;
    }
  }
}

body {
  .layout-mode-rtl {
    direction: rtl;
    .sidebar {
      right: 0;
      left: unset;
      border-right: 0;
      border-left: 1px solid $light;
      .sidebar-menu {
        > ul > li ul li a span {
          margin-right: 10px;
          margin-left: 0;
        }
        .menu-arrow {
          left: 10px;
          right: unset;
        }
        > ul > li .submenu-hdr::after {
          margin-right: 8px;
          margin-left: 0;
        }
      }
    }
    .header-left {
      float: right;
      border-right: 0;
      border-left: 1px solid $light;
    }
    .page-wrapper {
      margin-right: 252px;
      margin-left: 0;
    }
    &.mini-sidebar {
      .page-wrapper {
        margin-right: 80px;
      }
    }
    .header {
      .date-range {
        padding-right: 30px !important;
        padding-left: 10px !important;
      }
      .cal-icon {
        right: 10px;
        left: unset;
      }
      .searchinputs input {
        padding-left: 45px;
        padding-right: 10px;
      }
      .search-addon button {
        left: 16px;
        right: unset;
      }
    }
  }
}

.primary-topbar {
  .header {
    background: $primary;
    border-bottom-color: $primary-700;
    .dark-logo {
      display: block;
    }
    .logo-normal {
      display: none;
    }
    #toggle_btn {
      color: $white;
    }
    .bg-white {
      background: transparent !important;
    }
    .btn-outline-light {
      color: $white !important;
      border-color: $primary-700 !important;
    }
    .header-left {
      background: $primary;
      border-color: $primary-700;
    }
    .searchinputs {
      input {
        background: $primary;
        color: $white;
        border-color: $primary-700;
        &::placeholder {
          color: $white;
        }
      }
      .search-addon button {
        background: $primary;
        color: $white;
      }
    }
  }
  @include respond-above(lg) {
    .mini-sidebar .header-left .dark-logo {
      display: none;
    }
    .mini-sidebar.expand-menu .dark-logo {
      display: block;
    }
  }
}

.dark-data-sidebar {
  .sidebar {
    background: $dark-900;
    border-color: $dark-700;
    .sidebar-menu {
      > ul {
        > li {
          .submenu-hdr::after {
            background: $dark-700;
          }
          .submenu {
            > a.subdrop {
              background: $dark-700;
            }
          }
          &.active {
            a {
              span {
                color: $primary;
              }
            }
          }
          ul {
            li {
              &.active {
                a {
                  span,
                  i {
                    color: $primary;
                  }
                }
              }
              a {
                color: $dark-300;
                &:hover,
                &.active {
                  color: $primary;
                }
                i {
                  background: $dark-700;
                  color: $dark-300;
                }
                span {
                  color: $dark-300;
                }
              }
              &.active {
                a {
                  background: $dark-700;
                }
              }
              .submenu {
                > ul {
                  li {
                    a {
                      color: $dark-300;
                      &.active {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.primary-data-sidebar {
  .sidebar {
    background: $primary;
    border-color: $dark-700;
    .sidebar-menu {
      > ul {
        > li {
          .submenu-hdr {
            color: $white;
            &::after {
              background: $primary-100;
            }
          }
          .submenu {
            > a.subdrop {
              background: $primary-700;
              span {
                color: $white;
              }
            }
            a {
              &.active {
                .menu-arrow::before {
                  border-color: $primary-100;
                }
              }
            }
          }
          &.active {
            a {
              span {
                color: $white;
              }
            }
          }
          ul {
            li {
              &.active {
                a {
                  span,
                  i {
                    color: $primary;
                  }
                }
              }
              a {
                color: $primary-300;
                &:hover,
                &.active {
                  color: $white;
                }
                i {
                  background: $primary-100;
                  color: $primary;
                }
                span {
                  color: $primary-300;
                }
              }
              &.active {
                a {
                  background: $primary-700;
                }
              }
              .submenu {
                > ul {
                  li {
                    a {
                      color: $primary-300;
                      &.active {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkblack-data-sidebar {
  .sidebar {
    background: #39435f;
    border-color: $dark-700;
    .sidebar-menu {
      > ul {
        > li {
          .submenu-hdr {
            color: $white;
            &::after {
              background: $primary-100;
            }
          }
          .submenu {
            > a.subdrop {
              background: $primary-700;
              span {
                color: $white;
              }
            }
            a {
              &.active {
                .menu-arrow::before {
                  border-color: $primary-100;
                }
              }
            }
          }
          &.active {
            a {
              span {
                color: $white;
              }
            }
          }
          ul {
            li {
              &.active {
                a {
                  span,
                  i {
                    color: $primary;
                  }
                }
              }
              a {
                color: $primary-300;
                &:hover,
                &.active {
                  color: $white;
                }
                i {
                  background: $primary-100;
                  color: $primary;
                }
                span {
                  color: $primary-300;
                }
              }
              &.active {
                a {
                  background: $primary-700;
                }
              }
              .submenu {
                > ul {
                  li {
                    a {
                      color: $primary-300;
                      &.active {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkblue-data-sidebar {
  .sidebar {
    background: $sidebar-blue;
    border-color: $dark-700;
    .sidebar-menu {
      > ul {
        > li {
          .submenu-hdr {
            color: $white;
            &::after {
              background: $primary-100;
            }
          }
          .submenu {
            > a.subdrop {
              background: $primary-700;
              span {
                color: $white;
              }
            }
            a {
              &.active {
                .menu-arrow::before {
                  border-color: $primary-100;
                }
              }
            }
          }
          &.active {
            a {
              span {
                color: $white;
              }
            }
          }
          ul {
            li {
              &.active {
                a {
                  span,
                  i {
                    color: $primary;
                  }
                }
              }
              a {
                color: $primary-300;
                &:hover,
                &.active {
                  color: $white;
                }
                i {
                  background: $primary-100;
                  color: $primary;
                }
                span {
                  color: $primary-300;
                }
              }
              &.active {
                a {
                  background: $primary-700;
                }
              }
              .submenu {
                > ul {
                  li {
                    a {
                      color: $primary-300;
                      &.active {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dark-topbar {
  .header {
    background: $dark-900;
    border-bottom-color: $dark-700;
    .dark-logo {
      display: block;
    }
    .logo-normal {
      display: none;
    }
    .header-left {
      background: $dark-900;
      border-color: $dark-700;
    }
    .searchinputs {
      input {
        background: $dark-800;
        border-color: $dark-700;
        color: $dark-300;
      }
      .search-addon button {
        background: $dark-700;
        color: $dark-200;
      }
    }
    .bg-white {
      background: $dark-800 !important;
    }
    .btn-outline-light {
      border-color: $dark-700 !important;
      color: $dark-300 !important;
    }
    #toggle_btn {
      color: $dark-300;
    }
  }
  @include respond-above(lg) {
    .mini-sidebar .header-left .dark-logo {
      display: none;
    }
    .mini-sidebar.expand-menu .dark-logo {
      display: block;
    }
  }
}

.grey-topbar {
  .header {
    background: $dark-100;
    border-bottom-color: $dark-100;
    .dark-logo {
      display: none;
    }
    .logo-normal {
      display: block;
    }
    .header-left {
      background: $dark-100;
      border-color: $dark-300;
    }
    .searchinputs {
      input {
        background: $dark-100;
        border-color: $dark-200;
        color: $dark-300;
      }
      .search-addon button {
        background: $dark-200;
        color: $dark-700;
      }
    }
    .bg-white {
      background: $dark-200 !important;
    }
    .btn-outline-light {
      border-color: $dark-200 !important;
      color: $dark-700 !important;
    }
    #toggle_btn {
      color: $dark-300;
    }
  }
}

.default-data-sidebar.data-sidebar-1 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-1 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}
.primary-data-sidebar.data-sidebar-1 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-1 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-1 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}

.default-data-sidebar.data-sidebar-2 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-2 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.primary-data-sidebar.data-sidebar-2 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-2 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-2 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}

.default-data-sidebar.data-sidebar-3 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-3 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.primary-data-sidebar.data-sidebar-3 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-3 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-3 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}

.default-data-sidebar.data-sidebar-4 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-4 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.primary-data-sidebar.data-sidebar-4 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-4 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-4 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}

.default-data-sidebar.data-sidebar-5 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-05.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-5 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.primary-data-sidebar.data-sidebar-5 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-05.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-5 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-05.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-5 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-05.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}
.default-data-sidebar.data-sidebar-6 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: transparent;
    .sidebar-menu {
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark-data-sidebar.data-sidebar-6 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
    .sidebar-menu {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}

.primary-data-sidebar.data-sidebar-6 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
    .sidebar-menu {
      background: rgba(80, 110, 228, 0.85);
    }
  }
}
.darkblack-data-sidebar.data-sidebar-6 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
    .sidebar-menu {
      background: rgba(7, 57, 109, 0.85);
    }
  }
}
.darkblue-data-sidebar.data-sidebar-6 {
  .sidebar {
    background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
    .sidebar-menu {
      background: rgba(57, 67, 95, 0.85);
    }
  }
}

.violet-data-color {
  $primary: #673ab7;
  .sidebar .sidebar-menu > ul {
    & > li ul li {
      a {
        &:hover {
          color: $primary;
          span {
            color: $primary;
          }
        }
        &.active {
          color: $primary;
        }
      }
      &.active {
        a {
          span,
          i {
            color: $primary;
          }
        }
      }
    }
    li .submenu > a.active {
      color: $primary;
      i,
      span {
        color: $primary;
      }
      .menu-arrow {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
  .btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($primary, 7%);
      border: 1px solid darken($primary, 7%);
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn.show:hover,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: $primary;
    border-color: $primary;
  }
  .link-primary {
    color: $primary !important;
    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 7%);
    }
  }
  .text-primary {
    color: $primary !important;
  }
  .badge {
    &.badge-primary {
      background: $primary;
    }
  }
  .dataTables_paginate .pagination li.active a.page-link {
    background: $primary;
    border-color: $primary;
  }
  .bg-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  .border-bottom.border-primary {
    border-color: $primary !important;
  }
  .border-primary {
    border-color: $primary !important;
  }
  .nav-tabs.nav-tabs-bottom li a.active,
  .nav-tabs.nav-tabs-bottom li a:hover {
    border-bottom-color: $primary;
    color: $primary;
  }
  .badge {
    &.badge-soft-primary {
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
  .list-group {
    a {
      &.active {
        background: $primary-100;
        color: $primary;
      }
      &:hover {
        background: $primary-100;
        color: $primary;
      }
    }
  }
  .badge-primary-hover:hover {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
  .main-chat-blk .chat .chat-footer form .form-buttons {
    .btn.send-btn {
      background: $primary;
    }
  }
  .right-user-side .slime-grp .chat-footer form .send-chat a {
    background: $primary;
  }
  .custom-pagination .paginations {
    .page-wrap li a {
      &.active,
      &:hover {
        background: $primary;
      }
    }
  }
  .select2-container--default {
    &.select2-container--open.select2-container--below
      .select2-selection--single {
      border-color: $primary;
    }
    .select2-results__option--highlighted[aria-selected] {
      background: $primary;
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
    &.active {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .nav-tabs .nav-link {
    &:hover {
      color: $primary;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .profile-uploader.profile-uploader-two .drag-upload-btn .upload-btn span {
    color: $primary;
  }
  .sidebar-contact .toggle-theme {
    background-color: $primary;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
      color: $white;
    }
  }
}
.pink-data-color {
  $primary: #e83e8c;
  .sidebar .sidebar-menu > ul {
    & > li ul li {
      a {
        &:hover {
          color: $primary;
          span {
            color: $primary;
          }
        }
        &.active {
          color: $primary;
        }
      }
      &.active {
        a {
          span,
          i {
            color: $primary;
          }
        }
      }
    }
    li .submenu > a.active {
      color: $primary;
      i,
      span {
        color: $primary;
      }
      .menu-arrow {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
  .btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($primary, 7%);
      border: 1px solid darken($primary, 7%);
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn.show:hover,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: $primary;
    border-color: $primary;
  }
  .link-primary {
    color: $primary !important;
    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 7%);
    }
  }
  .text-primary {
    color: $primary !important;
  }
  .badge {
    &.badge-primary {
      background: $primary;
    }
  }
  .dataTables_paginate .pagination li.active a.page-link {
    background: $primary;
    border-color: $primary;
  }
  .bg-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  .border-bottom.border-primary {
    border-color: $primary !important;
  }
  .border-primary {
    border-color: $primary !important;
  }
  .nav-tabs.nav-tabs-bottom li a.active,
  .nav-tabs.nav-tabs-bottom li a:hover {
    border-bottom-color: $primary;
    color: $primary;
  }
  .badge {
    &.badge-soft-primary {
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
  .list-group {
    a {
      &.active {
        background: $primary-100;
        color: $primary;
      }
      &:hover {
        background: $primary-100;
        color: $primary;
      }
    }
  }
  .badge-primary-hover:hover {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
  .main-chat-blk .chat .chat-footer form .form-buttons {
    .btn.send-btn {
      background: $primary;
    }
  }
  .right-user-side .slime-grp .chat-footer form .send-chat a {
    background: $primary;
  }
  .custom-pagination .paginations {
    .page-wrap li a {
      &.active,
      &:hover {
        background: $primary;
      }
    }
  }
  .select2-container--default {
    &.select2-container--open.select2-container--below
      .select2-selection--single {
      border-color: $primary;
    }
    .select2-results__option--highlighted[aria-selected] {
      background: $primary;
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
    &.active {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .nav-tabs .nav-link {
    &:hover {
      color: $primary;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .profile-uploader.profile-uploader-two .drag-upload-btn .upload-btn span {
    color: $primary;
  }
  .sidebar-contact .toggle-theme {
    background-color: $primary;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
      color: $white;
    }
  }
}
.orange-data-color {
  $primary: #fd7e14;
  .sidebar .sidebar-menu > ul {
    & > li ul li {
      a {
        &:hover {
          color: $primary;
          span {
            color: $primary;
          }
        }
        &.active {
          color: $primary;
        }
      }
      &.active {
        a {
          span,
          i {
            color: $primary;
          }
        }
      }
    }
    li .submenu > a.active {
      color: $primary;
      i,
      span {
        color: $primary;
      }
      .menu-arrow {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
  .btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($primary, 7%);
      border: 1px solid darken($primary, 7%);
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn.show:hover,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: $primary;
    border-color: $primary;
  }
  .link-primary {
    color: $primary !important;
    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 7%);
    }
  }
  .text-primary {
    color: $primary !important;
  }
  .badge {
    &.badge-primary {
      background: $primary;
    }
  }
  .dataTables_paginate .pagination li.active a.page-link {
    background: $primary;
    border-color: $primary;
  }
  .bg-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  .border-bottom.border-primary {
    border-color: $primary !important;
  }
  .border-primary {
    border-color: $primary !important;
  }
  .nav-tabs.nav-tabs-bottom li a.active,
  .nav-tabs.nav-tabs-bottom li a:hover {
    border-bottom-color: $primary;
    color: $primary;
  }
  .badge {
    &.badge-soft-primary {
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
  .list-group {
    a {
      &.active {
        background: $primary-100;
        color: $primary;
      }
      &:hover {
        background: $primary-100;
        color: $primary;
      }
    }
  }
  .badge-primary-hover:hover {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
  .main-chat-blk .chat .chat-footer form .form-buttons {
    .btn.send-btn {
      background: $primary;
    }
  }
  .right-user-side .slime-grp .chat-footer form .send-chat a {
    background: $primary;
  }
  .custom-pagination .paginations {
    .page-wrap li a {
      &.active,
      &:hover {
        background: $primary;
      }
    }
  }
  .select2-container--default {
    &.select2-container--open.select2-container--below
      .select2-selection--single {
      border-color: $primary;
    }
    .select2-results__option--highlighted[aria-selected] {
      background: $primary;
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
    &.active {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .nav-tabs .nav-link {
    &:hover {
      color: $primary;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .profile-uploader.profile-uploader-two .drag-upload-btn .upload-btn span {
    color: $primary;
  }
  .sidebar-contact .toggle-theme {
    background-color: $primary;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
      color: $white;
    }
  }
}
.green-data-color {
  $primary: #2ca87f;
  .sidebar .sidebar-menu > ul {
    & > li ul li {
      a {
        &:hover {
          color: $primary;
          span {
            color: $primary;
          }
        }
        &.active {
          color: $primary;
        }
      }
      &.active {
        a {
          span,
          i {
            color: $primary;
          }
        }
      }
    }
    li .submenu > a.active {
      color: $primary;
      i,
      span {
        color: $primary;
      }
      .menu-arrow {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
  .btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($primary, 7%);
      border: 1px solid darken($primary, 7%);
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn.show:hover,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: $primary;
    border-color: $primary;
  }
  .link-primary {
    color: $primary !important;
    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 7%);
    }
  }
  .text-primary {
    color: $primary !important;
  }
  .badge {
    &.badge-primary {
      background: $primary;
    }
  }
  .dataTables_paginate .pagination li.active a.page-link {
    background: $primary;
    border-color: $primary;
  }
  .bg-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  .border-bottom.border-primary {
    border-color: $primary !important;
  }
  .border-primary {
    border-color: $primary !important;
  }
  .nav-tabs.nav-tabs-bottom li a.active,
  .nav-tabs.nav-tabs-bottom li a:hover {
    border-bottom-color: $primary;
    color: $primary;
  }
  .badge {
    &.badge-soft-primary {
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
  .list-group {
    a {
      &.active {
        background: $primary-100;
        color: $primary;
      }
      &:hover {
        background: $primary-100;
        color: $primary;
      }
    }
  }
  .badge-primary-hover:hover {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
  .main-chat-blk .chat .chat-footer form .form-buttons {
    .btn.send-btn {
      background: $primary;
    }
  }
  .right-user-side .slime-grp .chat-footer form .send-chat a {
    background: $primary;
  }
  .custom-pagination .paginations {
    .page-wrap li a {
      &.active,
      &:hover {
        background: $primary;
      }
    }
  }
  .select2-container--default {
    &.select2-container--open.select2-container--below
      .select2-selection--single {
      border-color: $primary;
    }
    .select2-results__option--highlighted[aria-selected] {
      background: $primary;
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
    &.active {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .nav-tabs .nav-link {
    &:hover {
      color: $primary;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .profile-uploader.profile-uploader-two .drag-upload-btn .upload-btn span {
    color: $primary;
  }
  .sidebar-contact .toggle-theme {
    background-color: $primary;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
      color: $white;
    }
  }
}
.red-data-color {
  $primary: #dc2626;
  .sidebar .sidebar-menu > ul {
    & > li ul li {
      a {
        &:hover {
          color: $primary;
          span {
            color: $primary;
          }
        }
        &.active {
          color: $primary;
        }
      }
      &.active {
        a {
          span,
          i {
            color: $primary;
          }
        }
      }
    }
    li .submenu > a.active {
      color: $primary;
      i,
      span {
        color: $primary;
      }
      .menu-arrow {
        &::before {
          border-color: $primary;
        }
      }
    }
  }
  .btn-primary {
    background-color: $primary;
    border: 1px solid $primary;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($primary, 7%);
      border: 1px solid darken($primary, 7%);
    }
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn.show:hover,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: $primary;
    border-color: $primary;
  }
  .link-primary {
    color: $primary !important;
    &:hover,
    &:focus,
    &:active {
      color: darken($primary, 7%);
    }
  }
  .text-primary {
    color: $primary !important;
  }
  .badge {
    &.badge-primary {
      background: $primary;
    }
  }
  .dataTables_paginate .pagination li.active a.page-link {
    background: $primary;
    border-color: $primary;
  }
  .bg-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  .border-bottom.border-primary {
    border-color: $primary !important;
  }
  .border-primary {
    border-color: $primary !important;
  }
  .nav-tabs.nav-tabs-bottom li a.active,
  .nav-tabs.nav-tabs-bottom li a:hover {
    border-bottom-color: $primary;
    color: $primary;
  }
  .badge {
    &.badge-soft-primary {
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $primary !important;
      border: 1px solid $primary !important;
    }
  }
  .list-group {
    a {
      &.active {
        background: $primary-100;
        color: $primary;
      }
      &:hover {
        background: $primary-100;
        color: $primary;
      }
    }
  }
  .badge-primary-hover:hover {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }
  .main-chat-blk .chat .chat-footer form .form-buttons {
    .btn.send-btn {
      background: $primary;
    }
  }
  .right-user-side .slime-grp .chat-footer form .send-chat a {
    background: $primary;
  }
  .custom-pagination .paginations {
    .page-wrap li a {
      &.active,
      &:hover {
        background: $primary;
      }
    }
  }
  .select2-container--default {
    &.select2-container--open.select2-container--below
      .select2-selection--single {
      border-color: $primary;
    }
    .select2-results__option--highlighted[aria-selected] {
      background: $primary;
    }
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
    &.active {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .nav-tabs .nav-link {
    &:hover {
      color: $primary;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .profile-uploader.profile-uploader-two .drag-upload-btn .upload-btn span {
    color: $primary;
  }
  .sidebar-contact .toggle-theme {
    background-color: $primary;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
      color: $white;
    }
  }
}

.student-slider-rtl,
.teacher-slider-rtl {
  &.owl-carousel .owl-stage-outer {
    height: 100% !important;
    .owl-stage {
      height: 100%;
      .owl-item {
        height: 100%;
      }
    }
  }
}
.student-slider-rtl.owl-carousel {
  .owl-nav {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 24px;
      height: 24px;
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-12;
      &.owl-prev {
        margin-left: 5px;
      }
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}
.teacher-slider-rtl.owl-carousel {
  .owl-nav {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 24px;
      height: 24px;
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-12;
      color: $dark;
      &.owl-prev {
        margin-left: 5px;
      }
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}

.dark-data-theme,
.darkmode {
  background: $dark-900;
  color: $dark-300;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-100 !important;
  }
  span,
  p,
  a {
    color: $dark-300;
  }
  a {
    &:hover {
      color: $primary;
    }
  }
  .bg-outline-info {
    background-color: transparent;
    color: $info;
  }
  .bg-outline-success {
    background-color: transparent;
    color: $success;
  }
  .bg-outline-warning {
    background-color: transparent;
    color: $warning;
  }
  .bg-outline-danger {
    background-color: transparent;
    color: $danger;
  }
  .nav-tabs li a {
    color: $dark-300;
  }
  span.bg-success,
  span.bg-danger,
  span.bg-info,
  span.bg-primary,
  span.bg-warning,
  span.bg-secondary,
  span.bg-skyblue {
    color: $white;
  }
  .accordion-item {
    border-color: $dark-700;
  }
  .accordion-header,
  .accordion-body,
  .accordion-button {
    background: $dark-800;
    border-color: $dark-700;
  }
  .btn.btn-white {
    background: $dark-800;
    border-color: $dark-700;
  }
  .btn-icon {
    i {
      color: $dark-300;
    }
  }
  header {
    .btn-outline-light {
      border-color: $dark-700 !important;
      color: #b9b7c0 !important;
    }
  }
  .btn-outline-light {
    border-color: $dark-700 !important;
  }
  .dropdown-menu {
    background: $dark-800;
    border-color: $dark-700;
    .dropdown-item {
      color: $dark-300 !important;
    }
  }
  .tax-invoice-info,
  .invoice-popup-head {
    background: $dark-700;
  }
  .bg-dark {
    background: $dark-800 !important;
  }
  .text-dark {
    color: $dark-300 !important;
  }
  .invoice-product-table,
  .payment-info,
  .invoice-table {
    border-color: $dark-700 !important;
  }
  .border,
  .border-end,
  .border-white,
  .border-bottom {
    border-color: $dark-700 !important;
  }
  .border-start {
    border-color: $dark-700 !important;
  }
  .border-start.border-skyblue {
    border-color: $indigo !important;
  }
  .border-start.border-info {
    border-color: $info !important;
  }
  .border-start.border-danger {
    border-color: $danger !important;
  }
  .custom-table .table-responsive {
    border-color: $dark-700;
  }
  .shadow-sm {
    box-shadow: 0px 4.4px 12px -1px rgba(44, 44, 44, 0.361) !important;
  }
  .form-control {
    background: $dark-800;
    border-color: $dark-700;
    color: $dark-300;
  }
  .form-label {
    color: $dark-300;
  }
  .nav-tabs.nav-tabs-solid {
    background: $dark-800;
  }
  .bg-light-500,
  .bg-light,
  .bg-light-400,
  .bg-light-gray,
  .bg-light-200 {
    background: $dark-700 !important;
    border-color: $dark-700 !important;
  }
  .primary-hover {
    &:hover {
      background: $primary !important;
    }
  }
  .bg-light-300,
  .bg-light-100 {
    background: $dark-700 !important;
  }
  .card-dropdown > a {
    background: $dark-700 !important;
  }
  .table {
    --bs-body-bg: $dark-800;
    thead {
      th {
        background: $dark-800 !important;
        color: $dark-100 !important;
        border-color: $dark-700 !important;
      }
    }
    tbody {
      td {
        color: $dark-300 !important;
        border-color: $dark-700 !important;
      }
      th {
        border-color: $dark-700 !important;
      }
      td {
        .text-dark {
          a {
            color: $dark-300;
          }
        }
      }
    }
  }
  .tab-style-6 {
    background: $dark-800;
  }
  .main-chat-blk {
    .chat-page-wrapper .left-chat-title {
      border-color: $dark-700;
    }
    .main-wrapper .content .sidebar-group {
      .sidebar {
        background: $dark-900;
        border-right: $dark-300;
      }
    }
    .chat_form {
      box-shadow: none;
    }
    .review-files p {
      color: $dark-300;
    }
    .chat {
      background: $dark-900;
      .chat-header {
        background: $dark-900;
        border-color: $dark-700;
      }
      .chat-body .messages .chats .message-content {
        background: $dark-700;
        color: $dark-300;
        &.chat-award-link a {
          color: $dark-300;
        }
      }
      .chat-footer form .smile-foot .action-circle {
        background: $dark-700;
        color: $dark-300;
      }
    }
  }
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
    background-color: $dark-700;
  }
  .btn.btn-white {
    color: $dark-200;
    &:hover {
      color: $white;
    }
  }
  .badge.bg-pending {
    color: $white !important;
  }
  .note-frame {
    color: $dark-200;
  }
  .offcanvas-header,
  .offcanvas-body {
    background: $dark-800;
  }
  .dropdown-menu .class-hover p {
    color: $title-color !important;
  }
  .bg-success-transparent h6,
  .bg-primary-transparent h6 {
    color: $title-color !important;
  }
  .link-slider .item p {
    color: $title-color !important;
  }
  .form-check-input {
    background-color: $dark-500;
    border-color: $dark-700;
  }
  .form-select {
    background-color: $dark-800;
    border-color: $dark-700 !important;
  }
  .select2-container--default {
    .select2-selection--single {
      background: $dark-800 !important;
      border-color: $dark-700;
      .select2-selection__rendered {
        border-color: $dark-700;
        background: $dark-800;
        color: $dark-300 !important;
      }
    }
    .select2-results > .select2-results__options {
      border-color: $dark-700;
      background: $dark-800;
    }
  }
  .select2-dropdown {
    border-color: $dark-700;
  }
  .bootstrap-tagsinput {
    background: $dark-800;
    border-color: $dark-700;
  }
  .modal {
    .modal-content {
      background: $dark-800;
      border-color: $dark-700 !important;
      .modal-header {
        border-color: $dark-700 !important;
      }
      .modal-footer {
        border-color: $dark-700 !important;
      }
    }
  }
  .animate-card {
    .text-default {
      color: #5d6369 !important;
    }
  }
  .notice-widget:before {
    background-color: $dark-700;
  }
  .custom-alert1 {
    background: $dark-800;
  }
  .card {
    background-color: $dark-800;
    border-color: $dark-700;
    box-shadow: 0px 4.4px 12px -1px rgba(44, 44, 44, 0.361);
    .card-header {
      border-color: $dark-700;
    }
    .card-footer {
      border-color: $dark-700;
    }
  }
  .owl-carousel .owl-nav button {
    background: $dark-800 !important;
    color: $dark-200 !important;
  }
  .login-or .span-or {
    background: $dark-800;
  }
  .header {
    background: $dark-900;
    border-bottom-color: $dark-700;
    .dark-logo {
      display: block;
    }
    .logo-normal {
      display: none;
    }
    .header-left {
      background: $dark-900;
      border-color: $dark-700;
    }
    .searchinputs {
      input {
        background: $dark-800;
        border-color: $dark-700;
        color: $dark-300;
      }
      .search-addon button {
        background: $dark-700;
        color: $dark-200;
      }
    }
  }
  .sidebar {
    background: $dark-900 !important;
    border-color: $dark-700;
    .sidebar-menu {
      background: $dark-900 !important;
      > ul {
        > li {
          .submenu-hdr::after {
            background: $dark-700;
          }
          .submenu {
            > a.subdrop {
              background: $dark-700;
            }
          }
          ul {
            li {
              a {
                color: $dark-300;
                &.active,
                &:hover {
                  color: $primary;
                }
                i {
                  background: $dark-700;
                  color: $dark-300;
                }
                span {
                  color: $dark-300;
                }
                &:hover {
                  span {
                    color: $primary;
                  }
                }
              }
              &.active {
                a {
                  background: $dark-700;
                  span {
                    color: $primary;
                  }
                }
              }
              .submenu {
                > ul {
                  li {
                    a {
                      color: $dark-300;
                      &.active {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .page-wrapper {
    .content {
      background: $dark-900;
    }
    .list-group-item {
      background: $dark-800;
      border-color: $dark-700 !important;
    }
    .border {
      border-color: $dark-700 !important;
    }
    .border-bottom {
      border-color: $dark-700 !important;
    }
    .border-top {
      border-color: $dark-700 !important;
    }
    .bg-white {
      background: $dark-800 !important;
    }
    .dropdown-menu {
      background: $dark-800;
      border-color: $dark-700;
      .dropdown-item {
        color: $dark-300 !important;
      }
    }
    .text-dark {
      color: $dark-300 !important;
    }
  }
  &.main-chat-blk {
    .main-wrapper {
      .content {
        .sidebar-group {
          .sidebar {
            background: $dark-800;
            border-right-color: $dark-700;
            .user-list li a:hover {
              background: $dark-700;
            }
          }
        }
        .action-circle {
          background: $dark-800 !important;
        }
      }
      .chat-page-wrapper .left-chat-title {
        border-bottom-color: $dark-700;
      }
    }
    .right-sidebar .right-sidebar-wrap {
      background: $dark-800;
      border-left-color: $dark-700;
    }
    .chat {
      background: $dark-800;
      .chat-header {
        background: $dark-800;
        border-bottom-color: $dark-700;
      }
    }
  }
  .part-name.sub-part-name,
  .more-icon a,
  .meet-call-menu-blk .video-call-action ul li a {
    background: $dark-800;
    border-color: $dark-700;
  }
  .card .card-title {
    color: $dark-100;
  }
  .popover .popover-body {
    background: $dark-800;
    border-color: $dark-700;
  }
  .popover .popover-header {
    background: $dark-800;
    border-color: $dark-700;
  }
  .popover {
    border-color: $dark-700;
  }
  .swal2-popup,
  .toast-header,
  .toast-body {
    background: $dark-800;
  }
  .toast-header .btn-close {
    color: $dark-100;
  }
  .scroll-demo,
  .icons-list li {
    border-color: $dark-700;
  }
  .select2-container--default .select2-selection--multiple {
    background: $dark-800;
    border-color: $dark-700;
  }
  .table > :not(caption) > * > * {
    background: $dark-800;
  }
  .breadcrumb .breadcrumb-item.active {
    color: $dark-200;
  }
  .filter-wrapper {
    border-color: $dark-700;
  }
  .list-tab ul {
    border-color: $dark-700;
  }
  .meet-call-menu-blk .video-call-action ul li a {
    color: $dark-100;
  }
  .main-chat-blk .user-list li a {
    &:hover {
      background: $dark-800;
    }
  }
  .chat-msg-blk .chats {
    &.chats-right {
      .message-content {
        &::before {
          border-left-color: $dark-800;
        }
      }
    }
    .message-content {
      background: $dark-800 !important;
      &::before {
        border-right-color: $dark-800;
      }
    }
  }
  .right-user-side .slime-grp .chat-footer {
    background: $dark-800;
  }
  .right-user-side .slime-grp .chat-footer form .chat_form {
    background: $dark-800;
    border-color: $dark-700;
    color: $dark-200;
  }
  .chat_form::placeholder {
    color: $dark-500 !important;
  }
  .dropdown-item:not(.active):hover,
  .dropdown-item:not(.active):focus,
  .dropdown-item:not(.active):active,
  .dropdown-item.active {
    background: $dark-400;
  }
  .card-dropdown .dropdown-menu li a:hover {
    background: $dark-400;
    color: $dark-100;
  }
  .btn-outline-light {
    color: $dark-300 !important;
    background: $dark-800 !important;
  }
  .bg-white {
    background: $dark-800 !important;
  }
  .user-list-item {
    background: $dark-700 !important;
  }
  .record-time span:before {
    background-color: $dark-800;
  }
  .nav-control button,
  .plyr--video .plyr__control {
    background: $dark-800;
    border-color: $dark-700;
  }
  .dataTables_paginate .pagination li a {
    color: $dark-200;
  }
  .bootstrap-datetimepicker-widget table th {
    color: $dark-200;
  }
  .list-tab ul li a {
    color: $dark-200;
  }
  .accordion .accordion-button.collapsed {
    background: $dark-800 !important;
  }
  .accordion .accordion-button.collapsed:after {
    background: $dark-800;
  }
  .accordion .accordion-button {
    background: $dark-800 !important;
  }
  span.icon-addon,
  .bookingrange {
    color: $dark-300;
  }
  .daterangepicker {
    background: $dark-800;
    border-color: $dark-700;
  }
  .daterangepicker:after {
    border-bottom-color: $dark-500;
  }
  .accordion-button:after {
    background: $dark-800;
  }
  .language-setup-table {
    border-color: $dark-700;
  }
  #calendar,
  #events {
    .fc-day {
      background: $dark-700;
      border-color: $dark-600;
    }
    .fc-daygrid-day-number {
      color: $dark-300;
    }
    .fc-scrollgrid {
      border-color: $dark-600;
    }
    .fc-col-header-cell-cushion {
      color: $dark-100;
    }
    .fc-daygrid-day.fc-day-today {
      background-color: $dark-800;
    }
    td,
    th {
      border-color: $dark-600;
    }
  }
}
body .dark-data-theme,
body .darkmode {
  background-color: $dark-900;
  &.mini-sidebar {
    .dark-logo {
      display: none !important;
    }
    &.expand-menu {
      .dark-logo {
        display: block !important;
      }
    }
  }
}
